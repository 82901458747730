import React from "react";

import { Switch, Route, BrowserRouter as Router } from "react-router-dom";
import { Header } from "../header/header";
import Home from "../home/home";
import { Resume } from "../resume/resume";
import { About } from "../about/about";
import { Projects } from "../projects/projects";


export const App = () => {
  return (
    <div className="app">
      <Router basename={process.env.PUBLIC_URL}>
        <Header />

        <Switch>
          <Route path="/resume">
            <Resume />
          </Route>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/projects">
            <Projects />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </Router>
    </div>
  );
};

export default App;
