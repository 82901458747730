import "../projects/projects.css";

export const Projects = () => {
  return (
<div className="projects">
<h1>⚠️</h1>
<p>under construction</p>

    </div>
  );

};