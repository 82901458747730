import "../app/App.css";

export const About = () => {
  return (
      <div className="about">
    <div className="about-text">
      <h3>about</h3>
      <p>hi, my name is christina. </p>
      <p>i manage discrete graphics marketing programs (us) at intel. before that, i graduated from san diego state university with a double major in marketing and asian studies.</p>
      <p>previously at intel, i played a pivotal role coordinating intel gamer days—an annual sales event. i was awarded an innovation award and a department recognition award for my achievements.</p>
      <p>outside of work, i can be found playing league or painting. i love traveling and hope to experience working abroad within the next few years. </p>
      <p>i am also very passionate about animal rescue and have fostered 7 dogs 🐶</p>
      
      
    </div>
    </div>
  );
};
