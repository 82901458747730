import "../app/App.css";

function Home() {
  return (
    <div className="body">
      <div className="menu">
      <a className="menu-link" href="/about">
          about
        </a>
        <a className="menu-link" href="/resume">
          resume
        </a>
        <a
          className="menu-link"
          target="_blank"
          href="http://linkedin.com/in/christinaqliu"
        >
          linkedin
        </a>
        <a className="menu-link" href="/projects">
          projects
        </a>
        <div className="my_div">
          <a target="_blank" href="mailto:christina.qliu@gmail.com">
            <span className="old-text menu-link">email</span>
            <span className="new-text menu-link">christina.qliu@gmail.com</span>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Home;
