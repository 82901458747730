import "../app/App.css";
import { Document, Page } from "react-pdf";
import resume from "../../pdfs/resume.pdf";
import { useWindowWidth } from "@react-hook/window-size/throttled";

export const Resume = () => {
  const onlyWidth = useWindowWidth();
  return (
    <div className="resume">
      <div className="resume-download-container">
        <a
          className="resume-download"
          href={resume}
          download={"Christina Liu Resume"}
        >
          download
        </a>
      </div>

      <div className="resume-document-container">
        <Document
          className="resume-document"
          file={{ url: resume }}
          noData=""
          loading="loading..."
          error="failed to load resume"
        >
          <Page pageNumber={1} width={Math.min(onlyWidth || 1000, 1000)} />
        </Document>
      </div>
    </div>
  );
};
